<template>
    <div>
        <div class="page-title">
            <div class="backButton" @click="goBack"></div>
            <div style="position: relative">
                {{ $t('message.StatusProgress') }}
                <div class="regular-text"><span class="grey" style="padding-left: 0">{{ $t('table.User') }}: </span>
                    {{ user.nickname }}
                </div>
            </div>
        </div>

        <progress-table :conditionsProgress="conditionsProgress" :userDowngrade="user.downgrade" :progress="progress"></progress-table>

        <template v-if="user.status && user.status>1">
            <div class="kpi-title pressed" style="margin-bottom: 20px;">
                <div style="position: relative">
                    <div style="max-width: 80%">{{ $t('kpi.leaderBonuses') }}</div>
                    <div class="regular-text kpi-description"
                         style="max-width: 500px">
                        <div v-html="$t('kpi.forBonusesYouNeedPopup')"></div>
                    </div>
                </div>
            </div>
            <kpi-popup :kpi="kpi" :progress="progress"/>
            <div class="white-bordered-block" style="margin-top: 30px">
                <countdown-block :timestamp="timestamp"/>
            </div>
            <div class="second-title pressed" style="margin-bottom: 20px;">
                <div style="position: relative">
                    {{ $t('kpi.kpiProgress') }}
                    <div class="regular-text kpi-description"
                         style="max-width: 500px; margin-top: 5px;">{{ $t('kpi.newDelegations') }}
                        {{ Math.round((kpiCompleted + 'e-6')).toLocaleString('ru-RU') }} /
                        {{ Math.round(kpi[user.status]?.amount + ('e-6')).toLocaleString('ru-RU') }} AXM
                    </div>
                </div>
            </div>
            <div class="progress-level-container" v-if="display">
                <div class="progress-status-icons" style="width: calc(100% - 17px);">
                    <div v-for="item in user.status" :style="'width:' + pointWidth + '%'"
                         :class="item == 1? 'hidden':''"
                         style="display: inline-flex; align-items: center" :key="item">
                        <div class="proposer-icon"
                             :style="['margin-left: calc(100% - ' +  (display === 'mobile' ? '10':'18') + 'px)',
                             'background-image: url(' + statusesSamples[item]?.icon + ')'
                             ]"></div>
                    </div>
                </div>
                <div class="progress-bar levels-progress-bar">
                    <div class="sub-bar" :style="'width:'+ Number(subBarWidth) +'%' "></div>
                    <div :class="levelProgress === 100? 'fill-green' : 'fill-blue'"
                         :style="'width:'+ Number(levelProgress) +'%' "></div>
                    <div class="fill" :style="'width:'+ Number(levelProgress) +'%' "></div>
                </div>
                <div v-if="bonusesTable" class="regular-text kpi-description pressed" style="margin-top: 10px;font-family: Inter Tight thin;">
                    {{ $t('kpi.currentEarning') }} ~{{ currentEarning > 0 ? Number(currentEarning).toLocaleString('ru-RU') : currentEarning }} AXM
                </div>
                <div class="regular-text kpi-description pressed color-link"
                     style="max-width: 100%; margin-bottom: 20px; opacity: 0.5; margin-top: 10px"
                     v-html="$t('kpi.showBonusesSize') ">
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import ProgressTable from "@/components/Accounts/ProgressTable";
import axios from "axios";
import {statuses} from "@/mixins/statuses";
import {statusesConditions} from "@/mixins/statusesConditions";
import {ACCOUNT, BASE_ROUTE, CONDITIONS_PROGRESS, KPI_PROGRESS} from "../../../routes/routes";
import CountdownBlock from "@/components/Tools/CountdownBlock";
import KpiPopup from "@/components/Tools/kpiPopup";


export default {
    name: "StatusProgress",
    components: {CountdownBlock, KpiPopup, ProgressTable},
    mixins: [statuses, statusesConditions],
    data() {
        return {
            currentAddress: '',
            levelProgress: 0,
            kpi: {},
            kpiCompleted: {},
            user: {
                nickname: '',
                progress: [],
                status: 0,
                downgrade: null,
            },
            progress:[],
            kpiPrevLevel: 0,
            kpiNextLevel: 0,
            display: null,
            bonusesTable: [],
            conditionsProgress: [],
        }
    },
    computed: {
        currentEarning() {
            let num = 0
            if (this.levelProgress && this.bonusesTable) {
                let dataObject = this.bonusesTable.find((item) => item.status === Number(this.kpiPrevLevel> this.user.status ? this.user.status:this.kpiPrevLevel))
                if (dataObject) {
                    return Number((dataObject.amountForOneWithOtherLevels + ('e-6'))).toFixed(2)
                }
            }
            return num.toFixed(2)
        },
        pointWidth() {
            if (this.user.status) {
                return (100 / (this.user.status - 1))
            }
            return 100
        },
        timestamp() {
            const d = new Date();
            const promoHour = 9;
            let UTCDay = (6 + 7 - d.getUTCDay()) % 7;

            if (UTCDay === 0 && d.getUTCHours() >= promoHour) {
                UTCDay = 7;
            }

            d.setUTCDate(d.getUTCDate() + UTCDay);
            d.setUTCHours(promoHour);
            d.setMinutes(0);
            d.setSeconds(0);
            return d;
        },
        subBarWidth() {
            let fullPieces = 0
            if (this.user.status && this.levelProgress < 100) {
                let piece = 100 / (this.user.status - 1)
                fullPieces = Math.floor(this.levelProgress / piece)
                return fullPieces * piece
            }
            return 0
        }
    },
    created() {
        this.currentAddress = this.$router.currentRoute._value.params.id;
        this.fetchAddressInfo()
    },
    mounted() {
        if (window.innerWidth > 767) {
            this.display = 'desktop'
        } else {
            this.display = 'mobile'
        }
    },
    methods: {
        onResize() {
            if (window.innerWidth > 767) {
                this.display = 'desktop'
            } else {
                this.display = 'mobile'
            }
        },
        fetchKpiProgress() {
            axios.get(KPI_PROGRESS + this.currentAddress)
                .then((res) => {
                    this.kpi = res?.data?.kpi;
                    this.kpiCompleted = res?.data?.completed?.amount;

                    for (var prop in this.kpi) {
                        if (this.kpi[prop].amount > this.kpiCompleted) {
                            this.kpiNextLevel = prop
                            if (prop > 0) {
                                this.kpiPrevLevel = prop - 1
                                let amountDiff = this.kpi[this.kpiNextLevel].amount - this.kpi[this.kpiPrevLevel].amount
                                let amountMoreThanPrev = this.kpiCompleted - this.kpi[this.kpiPrevLevel].amount
                                let percentBetweenTwo = amountMoreThanPrev / (amountDiff / 100)
                                let percentOnLevel = 100 / (this.user.status - 1)
                                let progress = (percentOnLevel * (this.kpiPrevLevel - 1)) + (percentOnLevel * percentBetweenTwo / 100)
                                if (progress < 100) {
                                    this.levelProgress = progress
                                } else {
                                    this.levelProgress = 100
                                }
                                return
                            }
                        } else if (prop == 7 && this.kpiCompleted >= this.kpi[prop].amount) {
                            this.levelProgress = 100
                            this.kpiPrevLevel = 7
                        }
                    }
                })
        },
        async fetchLeaderBonusesTable() {
            let stat = await fetch(BASE_ROUTE + "/promo/leader_bonus/v2");
            stat = await stat.json();

            this.bonusesTable = stat.bonuses;
        },
        fetchAddressInfo() {
            let params = '?fields=referral,ref_info'
            // let params = '?fields=balance,claimable,unbounding,referral,ref_info,claimed_total'
            axios({
                method: "get",
                url: ACCOUNT + this.currentAddress + params,
                timeout: 1000 * 20,
            }).then(response => {
                this.fetchStatusProgress()
                let referral = response.data?.referral
                this.user.downgrade = response.data?.refInfo.status_downgrade_at
                if (referral) {
                    this.user.nickname = referral.name
                    this.user.status = referral.status
                    this.fetchKpiProgress()
                    this.fetchLeaderBonusesTable()
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchStatusProgress() {
            axios.get(CONDITIONS_PROGRESS + this.currentAddress)
                .then((res) => {
                    this.conditionsProgress = res.data;
                    this.progress = [];
                    this.conditionsProgress.forEach(status => {
                        this.progress.push(this.calculateStatusProgress(status.criteria))
                    })
                })
        },

        calculateStatusProgress(criteria) {

            const calculatedCriteriaCount = criteria.length === 1 ? 1 : 3;

            return Number(Math.round((criteria.reduce((accumulator, currentCriteria) => {

                if (currentCriteria.rule === 'RULE_N_REFERRALS_WITH_X_REFERRALS_EACH' && criteria.length >= 4) {
                    return 0 + accumulator;
                }

                return (currentCriteria.actual_value / currentCriteria.target_value) + accumulator
            }, 0) / calculatedCriteriaCount) * 10000) + 'e-2');
        }
    }

}
</script>

<style scoped>

.kpi-title {
    margin-top: 30px;
    font-family: Inter Tight thin;
    font-weight: 100;
    font-size: 60px;
    max-width: 500px;
    line-height: 60px;
}

@media (max-width: 767px) {
    .kpi-title {
        font-size: 33px;
        line-height: 38px;
    }
}

.kpi-description {
    margin-top: 35px;
    font-family: Inter Tight thin;
    font-weight: 100;
    font-size: 17px;
    max-width: 420px;
}

@media (max-width: 767px) {
    .kpi-description {
        margin-top: 15px;
        font-size: 14px;
    }
}

.second-title {
    font-size: 35px;
    margin-top: 40px;
    margin-bottom: 20px;
}

@media (max-width: 767px) {
    .second-title {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 10px;
    }
}

.white-bordered-block {
    background: #fff;
    border: 1px solid rgba(195, 188, 208, .4);
    border-radius: 35px;
    box-shadow: 0 40px 120px -20px #1111150f;
    padding: 16px;
}

@media (max-width: 767px) {
    .white-bordered-block {
        border-radius: 18px;
    }
}

.progress-level-container {
    margin-bottom: 40px;
}

.progress-status-icons {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.levels-progress-bar {
    width: calc(100% - 17px);
}

.sub-bar {
    background-color: rgba(52, 255, 77, 1);
    opacity: 0.5;
    height: 100%;
    position: absolute;
    z-index: 97;
    border-radius: 14px 0 0 14px;
}

.proposer-icon {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    background-size: cover;
    border-radius: 12px;
    position: relative;
}

@media (max-width: 767px) {
    .proposer-icon {
        width: 20px;
        height: 20px;
        border-radius: 7px;
    }
}

.proposer-icon:after {
    content: "";
    display: block;
    height: 20px;
    width: 1px;
    position: absolute;
    background-color: color(srgb 0.9375 0.9368 0.9589);
    bottom: -20px;
    z-index: 98;
    left: 50%;
    transform: translate(-50%, 0%);
}

.hidden {
    width: 0 !important;
    display: none !important;
}

.color-link a {
    background-image: linear-gradient(279.48deg, #0086C9 -6.36%, #213874 71.61%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}

</style>