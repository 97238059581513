<template>
    <div style="width: 100%; box-sizing: border-box">
        <div class="blue-button open-kpi-button" @click="openModal = true">
            <div style="width: fit-content; display: inline-flex; align-items: center; margin: 0 auto;">
                {{ $t('kpi.openKpiButton') }}
                <img class="advantages-item-icon"
                     style="width: 14px; height: 10px; margin-left: 7px" src="@/assets/icons/arrow-right-white.svg">
            </div>
        </div>
        <Popup :openModal="openModal" @hidePopup="hidePopup" class="kpiPopup">
            <div class="status-detail">
                <div class="status-detail_body">
                    <div class="popup-title">{{$t('kpi.kpiConditionsTitle')}}</div>
                    <div class="first-block" v-html="$t('kpi.forBonusesYouNeedPopup')"></div>
                    <div class="status-detail_body_conditions">
                        <div class="item" style="padding: 10px 16px;">
                            <div class="item-subtext grey" style="text-align: left;">
                                <div v-html="$t('kpi.condition1Popup')"/>
                            </div>
                        </div>
                    </div>
                    <div class="statuses">
                        <template v-for="(item,index) in progress" :key="index">
                            <div class="statusRow" :class="index == 0? 'hidden':''">
                                <div class="status-icon"
                                     :style="'background-image: url(' + statusesSamples[index+1]?.icon + ')'"></div>
                                <div style="text-align: left; margin-left: 5px">
                                    <div>{{ statusesSamples[index + 1]?.name }}</div>
                                    <div style="font-size: 12px">{{ $t('kpi.newDelegations') }} {{Number(kpi[index + 1]?.amount + 'e-6').toLocaleString('ru-RU')}} AXM</div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </Popup>
    </div>

</template>

<script>
import Popup from "@/components/Tools/Popup";
import {statuses} from "@/mixins/statuses";
import {statusesConditions} from "@/mixins/statusesConditions";

export default {
    name: "kpiPopup",
    components: {Popup},
    mixins: [statuses, statusesConditions],
    data() {
        return {
            openModal: false,
            conditionsProgress: [],
            openStatusIndex: 0,
            openStatus: {},
        }
    },

    props: {
        kpi: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Array,
            default: ()=>{[]}
        },
    },

    created() {},

    methods: {
        hidePopup() {
            this.openModal = false
        },
        statusMore(index) {
            this.openModal = true
            this.openStatus = this.statusesSamples[index]
            this.openStatusIndex = index - 1;
        }
    }
}
</script>

<style lang="scss">
.popup-title{
    font-size: 24px;
    font-family: Inter Tight;
    text-align: left;
    margin-left: 10px;
}


.first-block {
    color: rgb(162, 155, 174);
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    margin: 5px 10px 10px 10px;
    max-width: 95%;
    font-family: Inter Tight thin;
}
.status-detail{
    height: 100%;
    overflow-y: auto;
}

.status-detail_body .item-subtext {
    font-size: 12px;
    font-family: Inter Tight thin;
    color: #0e0e0e;
}

.statuses{
    margin-top: 20px;
}

.statusRow{
    display: inline-flex;
    align-items: center;
    width: 96%;
    margin-left: 13px;
    margin-bottom: 10px;
    box-sizing: border-box;
}
.status-icon{
    margin-right: 5px;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-position: center;
    border-radius: 12px;
    flex-shrink: 0;
}

.progress {
    font-size: 13px;

    .positive-text {
        font-size: 13px;
    }
}

.progress-number {
    padding-bottom: 2px;
    font-weight: 100;
}
.open-kpi-button {
    margin-left: 0;
    margin-bottom: 20px;
    font-size: 15px;
    width: fit-content;
    margin-top: 24px;
    padding: 13px 20px;
    border-radius: 16px;
}

@media (max-width: 767px) {
    .open-kpi-button{
        box-sizing: border-box;
        width: 100%;
    }
}

.open-kpi-button:hover {
    transform: scale(1.05);
    transition: .3s;
}

.hidden{
    width: 0 !important;
    display: none!important;
}

.kpiPopup .tool-popup_inner{
    max-height: 80vh !important;
}
</style>