<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.BlockHeight') }}</td>
            <td class="text-center">{{ $t('table.Time') }}</td>
            <td class="text-center">{{ $t('table.Transactions') }}
                <div class="view-all active desktopOnly">
                    <router-link to="/transactions">{{ $t('table.Viewall') }}</router-link>
                </div>
            </td>
            <td class="text-center">{{ $t('table.Events') }}</td>
            <td class="text-center">{{ $t('table.emissionRate') }}</td>
            <td class="text-right" style=" display: block">{{ $t('table.Proposer') }}
                <div class="view-all desktopOnly">
                    <router-link to="/validators">{{ $t('table.Viewall') }}</router-link>
                </div>
            </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(block,index) in blocks" :key="index" class="table-row">
            <td class="blue-linear">
                <router-link :to="{ name: 'Block', params: { id: block.number }}">{{
                        block.number.toLocaleString('ru-RU')
                    }}
                </router-link>
            </td>
            <td class="default text-center">{{ timeCookingUTC(block.blockTime, true) }}</td>
            <td class="text-center">{{ block.TxCount.toLocaleString('ru-RU') }}</td>
            <td class="text-center">{{ block.events.toLocaleString('ru-RU') }}</td>
            <td v-if="block.EmissionRate" class="text-center">{{ block.EmissionRate * 100}}%</td>
            <td class="default text-right">
                <div class="proposer-in-table-container">
                    <div class="blue-text">
                        <router-link :to="{ name: 'Validator', params: { id: block.proposer.address }}">
                            {{ block.proposer.name }}
                        </router-link>
                    </div>
                    <div class="proposer-icon" :style="'background-image: url(' + block.proposer.icon + ')'"></div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "BlocksTable",
    props: {
        blocks: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {}
    },

    methods: {
        setViewAll() {

        },
    }
}
</script>

<style scoped>

</style>