<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Nickname') }}</td>
            <td class="text-center">
                <TableFilter @click="sortTable('status')" :is-ordered="sortOrder === 'status'"
                             :direction="sortDirection">{{ $t('table.Status') }}
                </TableFilter>
            </td>
            <td class="text-center">
                <TableFilter @click="sortTable('depth')" :is-ordered="sortOrder === 'depth'" :direction="sortDirection">
                    {{ $t('table.Level') }}
                </TableFilter>
            </td>
            <td class="text-center">
                <TableFilter @click="sortTable('delegated')" :is-ordered="sortOrder === 'delegated'" :direction="sortDirection">{{ $t('table.Delegated') }}</TableFilter>
            </td>
            <td class="text-center">
                <TableFilter @click="sortTable('unbonding')" :is-ordered="sortOrder === 'unbonding'" :direction="sortDirection">{{ $t('table.undelegating') }}</TableFilter>
            </td>
            <td class="text-center">
                <TableFilter @click="sortTable('teamSize')" :is-ordered="sortOrder === 'teamSize'"
                             :direction="sortDirection">{{ $t('table.teamSizeWithActive') }}
                </TableFilter>
            </td>
            <td class="text-right">{{ $t('table.Referrer') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user,index) in team" :key="index" class="table-row">
            <td class="default">

                <div class="proposerRow" style="align-items: center">
                    <div class="proposer-icon" :style="'background-image: url(' + user.logoUrl + ')'"></div>
                    <div>
                        <div class="blue-text" style="display: inline-flex;align-items: center;">
                            <span v-if="user.premium" class="premium-icon" style="margin-left: 2px; margin-right: 3px; margin-top: -2px;"></span>
                            <router-link :to="{ name: 'Address', params: { id: user.address }}">
                                {{
                                    user.nickname ? user.nickname.length > 22 ? shrinkString(user.nickname, 15, 6) : user.nickname : shrinkString(user.address, 15, 6)
                                }}
                            </router-link>
                            <a v-if="user.telegram" :href="'https://t.me/' + user.telegram" target="_blank">
                                <img style="width: 15px; height: 13px; margin-top: 4px; margin-left: 5px" src="@/assets/icons/telegram.png"></a>
                        </div>
                    </div>
                </div>

            </td>
            <td class="default text-center">{{ user.status }}</td>
            <td class="default text-center">{{ user.level }}</td>
            <td class="default text-center">{{ user.delegated.toLocaleString('ru-RU').replace(",", ".") }} AXM</td>
            <td class="default text-center">{{ user.unbonding.toLocaleString('ru-RU').replace(",", ".") }} AXM</td>
            <td class="default text-center">{{ user.teamCnt }}&nbsp;<span
                class="gray">(+{{ (user.teamCntDaily || 0).toLocaleString('ru-RU') }})</span>
                / {{ (user.teamActive || 0).toLocaleString('ru-RU') }}
            </td>
            <td class="default text-right blue-text">
                <router-link :to="{ name: 'Address', params: { id: user.referrerAddress }}">
                    {{ user.referrer }}
                </router-link>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import TableFilter from "@/components/Filters/TableFilter";

export default {
    name: "TeamTable",
    components: {TableFilter},
    props: {
        team: {
            type: Array,
            default: () => ([])
        },
    },

    data() {
        return {
            sortOrder: null,
            sortDirection: 1,
        }
    },

    methods: {
        sortTable(order) {
            if (this.sortOrder === order) {
                this.sortDirection = this.sortDirection === 1 ? -1 : 1;
            } else {
                this.sortOrder = order;
                this.sortDirection = 1;
            }
            this.$emit('update-sort', {order: this.sortOrder, direction: this.sortDirection})
        }
    }
}
</script>

<style scoped>

</style>