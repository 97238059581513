<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Height') }}</td>
            <td class="text-center">{{ $t('table.BlockHash') }}</td>
            <td class="text-center">{{ $t('table.TXS') }}</td>
            <td class="text-right">{{ $t('table.Timestamp') }}</td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(block,index) in blocks" :key="index"  class="table-row">
            <td class="blue-linear" ><router-link :to="{ name: 'Block', params: { id: block.height }}">{{ block.height?.toLocaleString('ru-RU') }}</router-link></td>
            <td class="text-center">{{shrinkString(block.hash, 15, 6)}}</td>
            <td class="default text-center">{{ block.TxCount }}</td>
<!--            <td class="default text-right">{{timeCooking(block.blockTime) }}</td>-->
            <td class="default text-right">{{timeCookingUTC(block.blockTime, true)  }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "ProposedBlocksTable",
        props: {
            blocks: {
                type: Array,
                default: () => ([])
            }
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>