<template>
    <div style="width: 100vw; height: 100vh; background-color: #fff;"
         v-if="otherSite && !authorizedUser.name"></div>
    <div>
        <div v-if="!maintenance" class="header-container">
            <meta :title="metaInfo.title"/>
            <header class="header" :class="mobileMenuOpen? 'fixed' : ''">
                <a href="/" class="logo"></a>
                <input class="side-menu" type="checkbox" id="side-menu" v-model="menu"/>
                <label @click="mobileMenuOpen = !mobileMenuOpen" class="hamb" for="side-menu"><span
                    class="hamb-line"></span></label>
                <nav class="nav mobileOnly">
                    <div class="mobile-nav-container">
                        <div>
                            <global-search placeholder="menu" @search-submit="closeMenu"></global-search>
                            <menu-items @clicked="menuClicked"></menu-items>
                        </div>

                        <wallet-connection v-if="display==='mobile'" mobile/>

                        <div class="menu-bottom" style="margin-top: 30px">
                            <div>
                                <lang-selector></lang-selector>
                                <div class="testnet selected-options" style="margin-bottom: 0">
                                    <span class="text">{{ $t('message.Mainnet') }}</span>
                                </div>
                            </div>
                            <div class="copyright"> {{ $t('message.Copyright') }}</div>
                        </div>
                    </div>

                </nav>
                <div class="nav desktopOnly">
                    <lang-selector></lang-selector>
                    <menu-dropdown></menu-dropdown>
                    <div>
                        <div class="testnet selected-options">
                            <span class="text">{{ $t('message.Mainnet') }}</span>
                        </div>
                    </div>
                    <wallet-connection v-if="display==='desktop'"/>
                </div>
            </header>
        </div>
        <div v-if="!maintenance" class="body">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <div v-if="!maintenance" class="copyright desktopOnly"> {{ $t('message.Copyright') }}</div>
        <div v-if="maintenance" class="maintenance">
            <div class="maintenance-item"><img class="maintenance-logo" src="@/assets/logo.png"></div>
            <div class="maintenance-item">
                We are preparing the Mainnet for launch. All data will appear after the start. More news in our Telegram
                channels.
            </div>
            <div class="maintenance-links maintenance-item">
                <a href="https://t.me/axiomeen" target="_blank"><img src="@/assets/icons/telegram.svg">Telegram Channel</a>
                <a href="https://t.me/axiomeru" target="_blank"><img src="@/assets/icons/telegram.svg">Телеграм
                    Канал</a>
                <a href="https://t.me/axiomede" target="_blank"><img
                    src="@/assets/icons/telegram.svg">Telegram-Kanal</a>
            </div>
        </div>
    </div>

</template>

<script>
import MenuDropdown from "@/components/Tools/MenuDropdown";
import MenuItems from "@/components/Tools/MenuItems";
import LangSelector from "@/components/Tools/LangSelector";
import GlobalSearch from "@/components/Filters/GlobalSearch";
import WalletConnection from "@/components/Tools/walletConnection";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    name: 'App',
    components: {
        WalletConnection,
        GlobalSearch,
        LangSelector,
        MenuDropdown,
        MenuItems,
    },
    data() {
        return {
            otherSite: false,
            mainPage: false,
            mobileMenuOpen: false,
            menu: false,

            metaInfo: {
                title: 'Axiome Chain Explorer'
            },

            maintenance: false,
            display: null
        }
    },
    computed: {
        ...mapGetters(['authorizedUser'])
    },
    created: function () {
        let token = localStorage.getItem("authToken");
        if (token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        }
        window.addEventListener('resize', this.onResize)
        var currentUrl = window.location.pathname;
        if (currentUrl === '/') {
            if (window.location.hostname === 'axiomechain.pro' ||
                window.location.hostname === 'localhost' ||
                window.location.hostname === 'www.axiomechain.pro') {
                    this.otherSite = true
            }

            this.mainPage = true
        }
        this.bus.on('hideMenu', () => {
            this.closeMenu()
        })

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    },
    mounted() {
        this.onResize()
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
            if (window.innerWidth > 767) {
                this.display = 'desktop'
            } else {
                this.display = 'mobile'
            }
        },
        openWalletInfo() {
            this.openWalletModal = true
        },
        menuClicked() {
            this.mobileMenuOpen = false;
            this.menu = false
        },

        closeMenu() {
            this.mobileMenuOpen = false;
            this.menu = false;
        },

    },
    watch: {},
}
</script>

<style lang="scss">
.mobile-nav-container {
    height: calc(100vh - 100px);
    height: calc(var(--vh, 1vh) * 100 - 80px);
    transition: 0.1s;
}

.language-options__item {
    padding: 8px;
}
</style>

