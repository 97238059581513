<template>
    <div>
        <div v-for="(selectedTransaction,index) in operations"
             style="padding-bottom: 15px; margin-left: 12px"
             :key="index"
             class="flex flex-col justify-center">
            <div class="mt-1 text-[0.75rem]" style="opacity: 0.6; font-size: 16px; color: rgb(0, 54, 255);">
                {{ selectedTransaction.title }}
            </div>
            <div class="my-2"
                 :style="'color:' +  selectedTransaction.c || '#000'">
                {{selectedTransaction.s}}{{Number(selectedTransaction.amount + 'e-6')}}
                AXM
            </div>
            <div class="text-sm text-umio-purple-blue" style="font-size: 13px">
                {{ new Date(selectedTransaction.time).toLocaleDateString() }}
                {{ new Date(selectedTransaction.time).toLocaleTimeString() }}
            </div>
            <div v-if="selectedTransaction.error"
                 class="text-[10px] text-umio-red text-center text-umio-purple-blue">
                {{ selectedTransaction.error}}
            </div>
            <div class="flex flex-col gap-4 mt-9">
                <template v-for="(item, iindex) in selectedTransaction?.fields" :key="iindex">
                    <div v-if="item.v" >
                        <p class="text-sm thin-text opacity-60 text-umio-black"
                           style="opacity: 0.6; font-size: 13px; padding-top: 5px">{{ item.l }}</p>
                        <div class="flex items-center justify-between" style="font-size: 13px">
                            <div class="flex items-center flex-nowrap gap-3">
                                <div class="inline-content">
                                    <template v-if="item.t==='num'">{{Number(item.v +'e-6')}}</template>
                                    <a class="text-umio-gradient" v-else-if="item.v.includes('axm')" target="_blank" :href="`/address/${item.v}?support=true`">{{item.v}}</a>
                                    <template v-else>{{item.v}}</template>
                                    <span v-if="item.n" class="text-[0.7rem] thin-text"> ({{item.n}})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TransactionsTable",
    props: {
        operations: {
            type: Array,
            default: () => ([])
        }
    },
}
</script>

<style scoped>

</style>