<template>
    <div class="notice" v-if="userDowngrade">
        {{ $t('message.statusWillDowngrade') }} <span style="font-family: Inter Tight;">{{ statusesSamples[currentHighestCompletedStatus + 1]?.name }}</span> {{ timeCookingUTCDateOnly(Date.parse(new Date(userDowngrade)) / 1000) }}
    </div>
    <div class="info-table-block">
        <table>
            <thead class="desktopOnly">
            <tr>
                <td>{{ $t('table.Status') }}</td>
                <td>{{ $t('table.Progress') }}</td>
                <td class="text-center"></td>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item,index) in progress" :key="index">
                <tr class="table-row">
                    <td class="default mobile-top">
                        <div class="proposerRow" style="align-items: center">
                            <div class="proposer-icon"
                                 :style="'background-image: url(' + statusesSamples[index+1]?.icon + ')'"></div>
                            <div>
                                <div>{{ statusesSamples[index + 1]?.name }}</div>
                            </div>
                        </div>
                    </td>
                    <td class="default progress desktopOnly">
                        <div class="progress-number text-nowrap" v-if="item === 100">
                            {{ $t('table.Got') }}: <span class="positive-text">{{ item }}%</span>
                        </div>
                        <div class="progress-number text-nowrap" v-else>
                            {{ $t('table.InProcess') }}: <span class="blue-text">{{ item }}%</span>
                        </div>
                        <div class="progress-bar">
                            <div :class="item === 100? 'fill-green' : 'fill-blue'"
                                 :style="'width:'+ item +'%' "></div>
                            <div class="fill" :style="'width:'+ item +'%' "></div>
                        </div>
                    </td>
                    <td class="default td-button mobile-top">
                        <more-button @click="statusMore(index+1)"></more-button>
                    </td>
                </tr>
                <tr class="mobileOnly">
                    <td class="default progress mobile-bottom" colspan="2">
                        <div class="progress-number text-nowrap" v-if="item === 100">
                            {{ $t('table.Got') }}: <span class="positive-text">{{ item }}%</span>
                        </div>
                        <div class="progress-number text-nowrap" v-else>
                            {{ $t('table.InProcess') }}: <span class="blue-text">{{ item }}%</span>
                        </div>
                        <div class="progress-bar">
                            <div :class="item === 100? 'fill-green' : 'fill-blue'"
                                 :style="'width:'+ item +'%' "></div>
                            <div class="fill" :style="'width:'+ item +'%' "></div>
                        </div>
                    </td>
                </tr>
            </template>
            </tbody>
        </table>
    </div>
    <Popup :openModal="openModal" @hidePopup="hidePopup">
        <div class="status-detail">
            <div class="status-detail_head">
                <div class="status-detail_head_icon"
                     :style="'background-image: url(' + openStatus.icon + ')'"></div>
                <div class="status-detail_head_name">{{ openStatus.name }}</div>
                <!--                <div class="status-detail_head_name_desc grey">{{ $t('table.Status') }}</div>-->
            </div>
            <div class="status-detail_body">
                <div class="status-detail_body_advantages">
                    <div class="title">{{ $t('table.Advantages') }}</div>
                    <template v-if="openStatus.id == 'Starter'">
                        <div class="item">{{ $t('statusDetail.advanages.Starter-1') }}</div>
                    </template>
                    <div v-else class="item" v-for="index in 2" :key="index">
                        <template v-if="$t('statusDetail.advanages.'+ openStatus.id + '-' +'n')">
                            {{ $t('statusDetail.advanages.' + openStatus.id + '-' + index) }}
                        </template>
                    </div>
                </div>
                <div class="status-detail_body_conditions">
                    <div class="title">{{ $t('table.Conditions') }}</div>
                    <template v-for="(criteria, index) in conditionsProgress[openStatusIndex].criteria" :key="index">
                        <div class="item"
                             v-if="openStatusIndex < 3 || criteria.rule !== 'RULE_N_REFERRALS_WITH_X_REFERRALS_EACH'">
                            {{
                                $t('statusDetail.conditions.' + statusesConditionsObjects[openStatus.id][criteria.rule])
                            }}
                            <div class="item-subtext grey">
                                {{ (+criteria.actual_value).toLocaleString('ru-RU').replace(",", ".") }} /
                                {{ (+criteria.target_value).toLocaleString('ru-RU').replace(",", ".") }}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </Popup>
</template>

<script>
import MoreButton from "@/components/Tools/MoreButton";
import Popup from "@/components/Tools/Popup";
import {statuses} from "@/mixins/statuses";
import {statusesConditions} from "@/mixins/statusesConditions";

export default {
    name: "ProgressTable",
    components: {MoreButton, Popup},
    mixins: [statuses, statusesConditions],
    data() {
        return {
            openModal: false,
            openStatusIndex: 0,
            openStatus: {},
        }
    },
    props: {
        userDowngrade: {
            type: String,
            default: ''
        },
        progress: {
            type: Array,
            default: ()=>{[]}
        },
        conditionsProgress: {
            type: Array,
            default: ()=>{[]}
        },
    },

    created() {},
    computed: {
       currentHighestCompletedStatus(){
           let lastIndex = this.progress.findLastIndex((item)=>item>=100)
           return lastIndex
       }
    },

    methods: {
        hidePopup() {
            this.openModal = false
        },
        statusMore(index) {
            this.openModal = true
            this.openStatus = this.statusesSamples[index]
            this.openStatusIndex = index - 1;
        },
    }
}
</script>

<style lang="scss">
.progress {
    font-size: 13px;

    .positive-text {
        font-size: 13px;
    }
}

.progress-number {
    padding-bottom: 2px;
    font-weight: 100;
}

.notice {
    padding: 16px;
    border: 1px solid #f7d7a7;
    background-color: #f9f1ea;
    font-family: Inter Tight thin;
    border-radius: 16px;
}
</style>