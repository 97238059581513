// export const BASE_ROUTE = 'https://api-devnet.axiomechain.org';
// export const BASE_ROUTE = 'https://testnet-api-pub.axiomechain.org';
export const BASE_ROUTE = document?.location?.hostname === "axiomechain.org" ? 'https://api-idx.axiomechain.org' : 'https://api-idx.axiomechain.pro';

// gives all validators list
export const VALIDATORS = BASE_ROUTE + "/validators/all";

//1.gives blocks by height blocks/1-15  100pcs MAX
//2.gives ONE by height blocks/20323
export const BLOCKS = BASE_ROUTE + "/blocks/";

export const LATEST = BASE_ROUTE + "/blocks/latest";

//1.gives txs with paginate paginate/:countOnPage/:pageNumber  100pcs MAX
//2.gives txs with filters paginate/5/1?type=MsgCreateProfile,MsgSend&address=axm1g2uhjn6yarvrt70gsywyp8pm2aatw6vmqztq2s&status=0&time=2023-12-08T10:19:13.000Z_2023-12-09T10:19:13.000Z
export const TRANSACTIONS_LIST = BASE_ROUTE + "/transactions/paginate/"

export const OPERATIONS = BASE_ROUTE + '/operations/';
export const FILTERS = BASE_ROUTE + '/blockchain/config/opfilter';

//gives ONE by tx HASH /by_txhash/E1B7724A86E252C53BE3DC0BB6794B48B4010AF8058CDCF0537A946BF9642D31
export const TRANSACTION = BASE_ROUTE + "/transactions/by_txhash/";

//gives user event count /events/paginate/records_count?address=axm1k9aufp337q777up20a6lqdatym7myfwedctgk2
export const EVENTS_USER_COUNT = BASE_ROUTE + "/events/paginate/records_count"
//gives user events with paginate /events/paginate/5/10?address=axm1k9aufp337q777up20a6lqdatym7myfwedctgk2
export const EVENTS_LIST = BASE_ROUTE + "/events/paginate/"

export const LATEST_CONSENSUS = BASE_ROUTE + "/consensus/latest";

export const TRANSACTIONS_COUNT = BASE_ROUTE + "/transactions/paginate/records_count";

//returns all available transaction types
export const TX_FILTER = BASE_ROUTE + "/blockchain/config/txfilter";

//1. gives one account data by Address /info/axm1k9aufp337q777up20a6lqdatym7myfwedctgk2
//2. gives data with parameters /info/axm1k9aufp337q777up20a6lqdatym7myfwedctgk2?fields=balance,claimable,referral
export const ACCOUNT = BASE_ROUTE + "/accounts/info/";

//1. returns team count /team/axm1crvjyrmfpfl7v9pjlj0cu2z6muptkazqc40m6c/paginate/records_count
//2. returns team list /team/axm1crvjyrmfpfl7v9pjlj0cu2z6muptkazqc40m6c/paginate/5/1
export const TEAM = BASE_ROUTE + "/accounts/team/";

//returns account progress in partnership program status-progress/axm1adepw972kyueq56z36zng5qx87282n4avxakw7
export const PROGRESS = BASE_ROUTE + "/accounts/status-progress/";

export const CONDITIONS_PROGRESS = BASE_ROUTE + '/accounts/status-progress-full/';
export const KPI_PROGRESS = BASE_ROUTE + '/accounts/status-kpi-full/';

// gives array of available claims from address /claimable/axm1k9aufp337q777up20a6lqdatym7myfwedctgk2
export const CLAIMABLE = BASE_ROUTE + "/accounts/claimable/";

export const VALIDATORS_ALL = BASE_ROUTE + "/validators/all";
export const VALIDATOR_DELEGATIONS = BASE_ROUTE + "/validators/delegations/";

export const CHAIN_STATISTIC_CACHE = BASE_ROUTE + "/blockchain/stat";

export const SEARCH = BASE_ROUTE + "/blockchain/search";

export const GENERATEAUTHTOKEN = "https://idx.ambdmn.com/connect/create_token";
export const TOKENINFO = "https://idx.ambdmn.com/connect/get_token_info";

export const GETPROMOCODES = "https://idx.ambdmn.com/promo/code/";