import { createApp } from 'vue'
import App from './App.vue'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import Popper from "vue3-popper";
import router from './router'
import moment from "moment";
import dayjs from 'dayjs'
import JsonViewer from 'vue-json-viewer'
import vClickOutside from "click-outside-vue3";
import VueClipboard, {copyText} from 'vue3-clipboard'
import mitt from 'mitt';
import Vue3TouchEvents from "vue3-touch-events";
import {i18n} from "../plugins/i18n";
import { Vue3Mq, MqResponsive } from "vue3-mq";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueYandexMetrika from 'vue3-yandex-metrika'
import store from './store'

const bus = mitt();
window._ = require('lodash');

if(window.location.hostname ==='axiomechain.org' ||
  window.location.hostname ==='axiomechain.pro' ||
  window.location.hostname ==='www.axiomechain.pro' ||
    window.location.hostname ==='www.axiomechain.org' ||
    window.location.hostname ==='axiomechain.pro' ||
    window.location.hostname ==='www.axiomechain.pro' ||
    window.location.hostname ==='www.dev.axiomechain.org' ||
    window.location.hostname ==='dev.axiomechain.org' ||
    window.location.hostname ==='localhost'){

const app = createApp(App)

app.mixin({
    data() {
        return {
            selectedLanguage: localStorage.getItem("language") || navigator.language,
        }
    },
    computed: {},
    methods:{
        goBack(){
            if (window.history.state.back === null) {
                this.$router.push({path: "/"});
            } else {
                this.$router.go(-1)
            }
        },
        timeCooking(timestamp) {
            try {
                let date = moment.unix(timestamp).lang(this.selectedLanguage || 'en').fromNow()
                return date;
            } catch (error) {
                return 'n/d'
            }
        },
        timeCookingFull(timestamp) {
            try {
                let date = moment.unix(timestamp)
                return date;
            } catch (error) {
                return 'n/d'
            }
        },
        timeCookingUTCDateOnly(timestamp){
            var utc = require('dayjs/plugin/utc')
            this.$dayjs.extend(utc)
            let date = moment.unix(timestamp)
            let utcDateFirst = this.$dayjs(date).utc().local().format('YYYY.MM.DD')
            return utcDateFirst;
        },
        timeCookingUTC(timestamp, useSecondsFrom=false, addFullTime = false) {
            var utc = require('dayjs/plugin/utc')
            this.$dayjs.extend(utc)
            try {
                let date = moment.unix(timestamp)
                if(useSecondsFrom){

                    let date1 = this.$dayjs(date).utc().local()
                    let date2 = this.$dayjs().utc().local()
                    let seconds  = Math.round(date2.diff(date1, 'seconds', true))
                    let mins  = Math.round(date2.diff(date1, 'minute', true))
                    let fullDateString = '';

                    if (addFullTime && ((seconds < 60  && seconds > -20) || (mins < 60 && mins > 0))) {
                        let utcDateFirst = this.$dayjs(date).utc().local().format('YYYY-MM-DD')
                        let text = this.$t('table.at')
                        let utcDateSecond = this.$dayjs(date).utc().local().format('HH:mm:ss')
                        let timezoneOffset = this.$dayjs().utcOffset()/ 60
                        let timezoneSign = timezoneOffset > 0 ?'+':''
                        let timezoneOffsetText =" UTC " + timezoneSign + timezoneOffset

                        fullDateString = ' (' + utcDateFirst+ ' ' + text+ ' ' + utcDateSecond + timezoneOffsetText + ')';
                    }

                    if(seconds < 60  && seconds > -20){
                        if(seconds < 0 && seconds > -20){
                            return '0' + ' ' + this.$t('table.secAgo') + fullDateString
                        }
                        return seconds + ' ' + this.$t('table.secAgo') + fullDateString
                    }

                    if(mins < 60 && mins > 0){
                        return mins  + ' ' + this.$t('table.minsAgo') + fullDateString
                    }
                }
                let utcDateFirst = this.$dayjs(date).utc().local().format('YYYY-MM-DD')
                let text = this.$t('table.at')
                let utcDateSecond = this.$dayjs(date).utc().local().format('HH:mm:ss')
                let timezoneOffset = this.$dayjs().utcOffset()/ 60
                let timezoneSign = timezoneOffset > 0 ?'+':''
                let timezoneOffsetText =" UTC " + timezoneSign + timezoneOffset

                return utcDateFirst+ ' ' + text+ ' ' + utcDateSecond + timezoneOffsetText;
            } catch (error) {
                return 'n/d'
            }
        },
        shrinkString(originStr, maxChars, trailingCharCount) {
            let shrinkedStr = originStr;
            const shrinkedLength = maxChars - trailingCharCount - 3;
            if (originStr.length > shrinkedLength) {
                const front = originStr.substr(0, shrinkedLength);
                const mid = '...';
                const end = originStr.substr(-trailingCharCount);
                shrinkedStr = front + mid + end;
            }

            return shrinkedStr;
        },
        doCopy(text) {
            copyText(text, undefined, (error) => {
                if (error) {
                    alert('Can not copy')
                }
            })
        },
    }
})

const options = {};

app.component("PopperTooltip", Popper);
app.component('mq-responsive', MqResponsive);
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})
app.use(VueYandexMetrika, {
    id: 95985719,
    router: router,
    env: "production",
    options: {'clickmap':true, 'trackLinks':true, 'accurateTrackBounce':true}
})
app.use(Toast, options);
app.use(vClickOutside)
app.use(VCalendar, {})
app.use(JsonViewer)
app.use(i18n)
app.use(router)
app.use(Vue3TouchEvents)
app.use(store)
app.use(Vue3Mq, {
    breakpoints: {
        mobile: 0,
        tablet: 768,
        wideScreen: 1200,
    }
});
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.bus = bus;
app.mount('#app')

}
