<template>
    <div>
        <div class="block-detail-info">
            <div class="block-detail-info-header">
                <div class="subtitle-1" style="min-width: 78px">{{ $t('table.Transaction') }}</div>
                <div class=" subvalue-1 inline">
                    <div class="hash hash-black">{{transaction.hash}}</div>
                    <copy-button :text="transaction.hash" black></copy-button>
                </div>
            </div>
            <div class="block-detail-info-body">
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.BlockHeight') }}</div>
                    <div class="subvalue-1 block-height">
                        <div class="inline-flex">
                            <router-link :to="{ name: 'Block', params: { id: transaction.height }}">
                                {{transaction.height?.toLocaleString('ru-RU')}}
                            </router-link>
                            <copy-button :text="transaction.height"></copy-button>
                        </div>
                    </div>
                </div>
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.Status') }}</div>
                    <div v-if="transaction.status === 0" class="subvalue-1 positive-text">
                       {{ $t('table.Success') }}
                    </div>
                    <div v-else class="subvalue-1 negative-text">
                        {{ $t('table.Error') }} ({{transaction.status}})
                    </div>
                </div>
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.Gas') }}</div>
                    <div class="subvalue-1">{{transaction.gas?.used?.toLocaleString('ru-RU')}}/{{transaction.gas?.requested?.toLocaleString('ru-RU')}}</div>
                </div>
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.Fee') }}</div>
                    <div class="subvalue-1">{{ Number(transaction.fee + 'e-6').toFixed(6)}} AXM</div>
                </div>
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.ChainID') }}</div>
                    <div class="subvalue-1">{{transaction.chainId}}</div>
                </div>
                <div class="info-block">
                    <div class="subtitle-1">{{ $t('table.Time') }}</div>
                    <div class="subvalue-1">{{timeCookingUTC(transaction.txTime, true, true) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="block-detail-info full-raw open" style="padding-right: 20px">
            <div class="block-detail-info-body">
                <div class="tabs">
                    <div class="tab" :class="{ selected: selectedTab === 'Message' }" @click="selectedTab = 'Message'">{{ $t("table.Message") }}</div>
                    <div class="tab" :class="{ selected: selectedTab === 'EventLogs' }" @click="selectedTab = 'EventLogs'">{{ $t("table.EventLogs") }}</div>
                </div>
                <div v-if="selectedTab === 'Message'">
                    <div class="info-block message">
                        <span class="message-gradient">{{ transaction.message }}</span>
                    </div>
                    <div class="info-block" v-if="messages[0]?.from_address">
                        <div class="subtitle-1">{{ $t("table.Sender") }}</div>
                        <div class="subvalue-1 block-height inline">
                            <router-link :to="{ name: 'Address', params: { id: messages[0].from_address  }}">{{ messages[0].from_address }}</router-link>
                            <copy-button :text="messages[0].from_address"></copy-button>
                        </div>
                    </div>
                    <div class="info-block" v-if="messageAmount">
                        <div class="subtitle-1">{{ $t("table.sum") }}</div>
                        <div class="subvalue-1">{{ (+(Number(messageAmount + 'e-6').toFixed(2))).toLocaleString('ru-RU').replace(',', '.') }} AXM</div>
                    </div>
                    <div class="info-block">
                        <div class="subtitle-1">{{ $t("table.Summary") }}</div>
                        <div class="subvalue-1" v-if="transaction.summary && transaction.summary.length">
                            <div class="summary-container">
                                <div v-for="(item, index) in transaction.summary" :key="index" class="summary-item">
                                    <span class="blue-text" style="padding-right: 5px">{{ shrinkString(item.address, 15, 6) }}</span>
                                    <span>{{ item.result }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grey-block" v-for="(message,index) in messages" :key="index">
                        <json-viewer :value="message" :expand-depth="4" theme="my-awesome-json-theme"></json-viewer>
                    </div>
                </div>
                <div v-else>
                    <div v-for="(item, index) in events" :key="index">
                        <div class="title">[{{index}}] {{item.type}}</div>
                        <div class="grey-block">
                            <json-viewer :value="item.attributes" :expand-depth="2" theme="my-awesome-json-theme"></json-viewer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import CopyButton from "@/components/Tools/CopyButton";
    export default {
        name: "TransactionInfo",
        components: {CopyButton},
        props: {
            transaction: {
                type: Object,
                default: () => ({})
            },
            messages: {
                type: Array,
                default: () => ([])
            },
            events: {
                type: Object,
                default: () => ({})
            }
        },
        computed: {
            messageAmount() {

                if (!this.messages[0]?.amount) {
                    return 0;
                }

                if (this.messages[0].amount.amount) {
                    return this.messages[0].amount.amount;
                }

                return this.messages[0].amount.reduce((accumulator, amount) => {
                    if (amount.amount && amount.denom === 'uaxm') {
                        return accumulator + +amount.amount;
                    }
                    return accumulator;
                }, 0)
            }
        },
        data() {
            return {
                jsonData: {},
                eventLogs: {},
                selectedTab: "Message",
            }
        },
        methods: {
            changeSelectedTab(tab) {
                this.selectedTab = tab;
            },
        },
    }
</script>

<style scoped>
.tabs {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.tabs .tab {
  padding: 8px 12px;
  border-radius: 12px;
  color: #727376;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-weight: 600;
}
.tabs .tab.selected {
  color: black;
  background: #f2f2f2;
}
</style>