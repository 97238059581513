<template>
    <table>
        <thead>
        <tr>
            <td>{{ $t('table.Validator') }}</td>
            <td class="text-center">
                <TableFilter @click="sortTable('locked')" :is-ordered="sortOrder === 'locked'" :direction="sortDirection" >{{ $t('table.LockedAXM') }}</TableFilter>
            </td>
            <td class="text-center">
                <div class="more-wide-inline">
                    {{ $t('table.delegationFee') }}
                    <PopperTooltip class="light" arrow placement="bottom"
                                   :content="$t('tooltips.delegationFee')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{$t('tooltips.delegationFee')}}</div>
                        </template>
                    </PopperTooltip>
                </div>

            </td>
            <td class="text-center">
                <div class="more-wide-inline">
                    {{ $t('table.txFee') }}
                    <PopperTooltip class="light" arrow placement="bottom"
                                   :content="$t('tooltips.txFee')">
                        <div class="help-icon"></div>
                        <template #content>
                            <div class="popper-content" style="width: 230px">{{$t('tooltips.txFee')}}</div>
                        </template>
                    </PopperTooltip>
                </div>
            </td>
            <td class="text-center">
                <TableFilter @click="sortTable('votingPower')" :is-ordered="sortOrder === 'votingPower'" :direction="sortDirection" >{{ $t('table.VotingPower') }}</TableFilter>
            </td>
            <td class="text-right">
                <TableFilter @click="sortTable('uptime')" :is-ordered="sortOrder === 'uptime'" :direction="sortDirection" >{{ $t('table.Uptime') }}</TableFilter>
            </td>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(validator,index) in validators" :key="index" class="table-row">
            <td class="default">
                <router-link :to="{ name: 'Validator', params: { id: validator.address }}">
                    <div class="proposerRow">
                        <div class="proposer-icon" :style="'background-image: url(' + validator.icon + ')'"></div>
                        <div>
                            <div class="blue-text">{{ validator.name }}</div>
                            <div class="proposer-address grey">{{ shrinkString(validator.userAddress, 15, 6) }}</div>
                        </div>
                    </div>
                </router-link>
            </td>
            <td class="default text-center text-nowrap">{{ Math.round(validator.locked).toLocaleString('ru-RU') }} AXM</td>
            <td class="default text-center">{{ validator.delegationFee }}%</td>
            <td class="default text-center">{{ validator.txFee }}%</td>
            <td class="default text-center">{{ validator.votingPower ? validator.votingPower + '%' : '-' }}</td>
            <td class="default text-center">{{ validator.uptime ? validator.uptime?.toFixed(2) + '%' : '-' }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import TableFilter from "@/components/Filters/TableFilter";

export default {
    name: "ValidatorsTable",
    components: {TableFilter},
    props: {
        validators: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            sortOrder: null,
            sortDirection: 1,
        }
    },
    methods: {
        sortTable(order) {
            if (this.sortOrder === order) {
                this.sortDirection = this.sortDirection === 1 ? -1 : 1;
            } else {
                this.sortOrder = order;
                this.sortDirection = 1;
            }
            this.$emit('update-sort', {order: this.sortOrder, direction: this.sortDirection})
        }
    }
}
</script>

<style scoped>
.more-wide-inline{
    white-space: nowrap;
    padding-right: 20px;
}
</style>