<template>
    <div class="block-detail-info" v-if="block.number">
        <div class="block-detail-info-header">
            <div class="subtitle-1">{{ $t('table.Block') }}</div>
            <div class="block-number">{{ block.number?.toLocaleString('ru-RU') }}</div>
        </div>
        <div class="block-detail-info-body">
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.BlockHash') }}</div>
                <div class="subvalue-1 inline">
                    <div class="hash hash-black">{{ block.hash }}</div>
                    <copy-button :text="block.hash" black></copy-button>
                </div>
            </div>
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.ChainID') }}</div>
                <div class="subvalue-1">{{ block.chainID }}</div>
            </div>
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.Proposer') }}</div>
                <div class="subvalue-1 blue-text">
                    <div class="inline">
                        <div class="proposer-icon">
                            <img :src="block.proposer.icon" alt=""></div>
                        <router-link :to="{ name: 'Validator', params: { id: block.proposer.address }}">
                            {{ block.proposer.name }}
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.Timestamp') }}</div>
<!--                <div class="subvalue-1">{{ timeCookingFull(block.blockTime) }}</div>-->
                <div class="subvalue-1">{{ timeCookingUTC(block.blockTime) }}</div>
            </div>
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.Transactions') }}</div>
                <div class="subvalue-1">{{ block.TxCount }}</div>
            </div>
            <div class="info-block">
                <div class="subtitle-1">{{ $t('table.Events') }}</div>
                <div class="subvalue-1">{{ block.EvCount }}</div>
            </div>
            <div class="info-block" v-if=" block.EmissionRate">
                <div class="subtitle-1">{{ $t('table.emissionRate') }}</div>
                <div class="subvalue-1">{{ block.EmissionRate*100 }}%</div>
            </div>
        </div>
    </div>
</template>

<script>
import CopyButton from "@/components/Tools/CopyButton";
export default {
    name: "BlockInfo",
    components: {CopyButton},
    props: {
        block: {
            type: Object,
            default: () => ({})
        }
    },
}
</script>

<style scoped>

</style>